import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import ErrorImg from '../images/error_page/error_page_img.png';

export default function error() {
  return (
    <Layout>
      <div className='d-flex text-center flex-column p-2'>
        <h1>
          <strong>Error 404: Seite nicht gefunden</strong>
        </h1>
        <h5>
          Mögliche Gründe: die Seite existiert nicht oder die URL ist nicht
          korrekt.
        </h5>
        <Link to='/'>
          <button className='button-md'>
            Bring mich zurück zur Startseite
          </button>
        </Link>
        <div className='col'>
          <img className='img-fluid' src={ErrorImg} alt='error' />
        </div>
      </div>
    </Layout>
  );
}
